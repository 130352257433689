import {
  PageHeader as AntdPageHeader,
  PageHeaderProps as AntdPageHeaderProps,
} from "@ant-design/pro-layout/es/components/PageHeader";
import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings, colors, fonts } = DesignTokens;

export type ContentHeaderProps = AntdPageHeaderProps;

const StyledContentHeader = styled(AntdPageHeader)`
  padding: ${spacings.xlarge * 2 + 10}px ${spacings.xlarge}px
    ${spacings.middle}px ${spacings.xlarge}px;
  border-bottom: 1px solid ${colors.gray.light};
  background-color: ${colors.gray.superLight};

  .ant-page-header-heading-title {
    font-weight: 500;
    font-size: ${fonts.sizes.headings.xlarge}px;
    font-family: ${fonts.sansSerifMd};
  }

  .ant-page-header-heading-left {
    margin-top: 0;
  }

  .ant-page-header-heading-extra {
    display: flex;
  }

  &.ant-page-header-has-breadcrumb {
    /* Needed because we want to have the buttons on the right correct aligned */
    padding-top: ${spacings.xlarge + 10}px;
  }
`;

//With https://github.com/ant-design/pro-components/pull/8491 ghost became true by default
//We set ghost now by default to false, but allow override via props
export const ContentHeader: React.FC<ContentHeaderProps> = (props) => (
  <StyledContentHeader ghost={false} {...props} />
);
