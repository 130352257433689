import { useTranslation } from "react-i18next";
import { DesignTokens, Drawer, Tabs } from "melco-ui";
import styled from "styled-components";
import { ChooseDesignList } from "./ChooseDesignList";
import { ChooseDesignCollectionList } from "./ChooseDesignCollectionList";
import { DrawerType } from "melco-shared-logic";

const { spacings } = DesignTokens;

const { TabPane } = Tabs;

type ChooseDesignDrawerProps = {
  drawer: DrawerType;
  formikPrefix: string;
};

const TabSpacer = styled.div`
  width: ${spacings.xlarge}px;
`;

export const ChooseDesignDrawer: React.FC<ChooseDesignDrawerProps> = ({
  drawer,
  formikPrefix,
}) => {
  const { t } = useTranslation();
  const { closeDrawer, isDrawerOpen } = drawer;

  const defaultActiveKey = drawer.drawerField.name.includes("design_collection")
    ? "design-collections"
    : "designs";

  return (
    <Drawer
      title={t("products.actions.choose_design.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={closeDrawer}
      open={isDrawerOpen}
      bodyStyle={{ padding: 0 }}
    >
      <Tabs
        defaultActiveKey={defaultActiveKey}
        tabBarExtraContent={{ left: <TabSpacer />, right: <TabSpacer /> }}
      >
        <TabPane
          key="designs"
          tab={t("products.actions.choose_design.tabs.designs")}
        >
          <ChooseDesignList drawer={drawer} formikPrefix={formikPrefix} />
        </TabPane>

        <TabPane
          key="design-collections"
          tab={t("products.actions.choose_design.tabs.design_collections")}
        >
          <ChooseDesignCollectionList
            drawer={drawer}
            formikPrefix={formikPrefix}
          />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};
