import { ConfigProviderProps, useMelcoUITheme } from "melco-ui";
import { useAntdLocale } from "../translation/useAntdLocale";

export const useConfigProvider = (): ConfigProviderProps => {
  const locale = useAntdLocale();
  const theme = useMelcoUITheme();

  return {
    locale,
    theme,
  };
};
