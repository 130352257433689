import React from "react";
import AntdButton, { ButtonProps as AntdButtonProps } from "antd/es/button";
import styled, { css } from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { fonts } = DesignTokens;

const StyledButton = styled(AntdButton).withConfig({
  shouldForwardProp: (prop) => !["isHero", "hasHeroHeight"].includes(prop),
})<ButtonProps>`
  ${({ isHero, hasHeroHeight }) => css`
    ${isHero &&
    `&& {
      font-family: ${fonts.sansSerifMd};
      font-size: ${fonts.sizes.xlarge}px;
    }`}

    ${(isHero || hasHeroHeight) &&
    `&.ant-btn-lg {
      line-height: inherit;
    }
    && {
      height: 40px;
      padding-top: 4px;
      padding-bottom: 4px;
    }`}
  `}
`;

type MelcoProps = {
  isHero?: boolean;
  hasHeroHeight?: boolean;
};

export type ButtonProps = MelcoProps & AntdButtonProps;

const Button: React.FC<ButtonProps> = (props) => <StyledButton {...props} />;

export { Button };
