import { useState } from "react";
import {
  Col,
  DesignTokens,
  FullWidthSpace,
  RangePicker,
  Row,
  Title,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { isDateWithinLast365Days } from "../../../helper/analyticsHelper";
import { StatisticsCardRow } from "./StatisticsCardRow";

const { spacings } = DesignTokens;

type StatisticsFilterType = {
  startTimestamp: Date;
  endTimestamp: Date;
};

export const Statistics: React.FC = () => {
  const { t } = useTranslation();

  const [statisticsFilter, setStatisticsFilter] = useState<
    StatisticsFilterType | undefined
  >({
    startTimestamp: dayjs().startOf("month").toDate(),
    endTimestamp: dayjs().endOf("day").toDate(),
  });

  const isDateNotWithinLast365Days = (date: Dayjs): boolean => {
    return !isDateWithinLast365Days(date.toDate());
  };

  return (
    <FullWidthSpace
      direction="vertical"
      size="small"
      data-cy-id="dashboard-statistics"
    >
      <Row gutter={[spacings.middle, spacings.middle]}>
        <Col>
          <Title level={4}>{t("dashboard.statistics.title")}</Title>
        </Col>
        <Col flex="auto" style={{ textAlign: "right" }}>
          <RangePicker
            format="L"
            disabledDate={isDateNotWithinLast365Days}
            onChange={(value) => {
              const startTimestamp =
                (value && value[0] && value[0].startOf("day").toDate()) ??
                undefined;
              const endTimestamp =
                (value && value[1] && value[1].endOf("day").toDate()) ??
                undefined;

              setStatisticsFilter(
                (startTimestamp &&
                  endTimestamp && {
                    startTimestamp,
                    endTimestamp,
                  }) ||
                  undefined
              );
            }}
            value={
              statisticsFilter?.startTimestamp &&
              statisticsFilter?.endTimestamp && [
                dayjs(statisticsFilter?.startTimestamp),
                dayjs(statisticsFilter?.endTimestamp),
              ]
            }
            allowClear={false}
          />
        </Col>
      </Row>

      <StatisticsCardRow
        startTimestamp={statisticsFilter?.startTimestamp}
        endTimestamp={statisticsFilter?.endTimestamp}
      />
    </FullWidthSpace>
  );
};
