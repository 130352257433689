import { useTranslation } from "react-i18next";
import { Drawer } from "melco-ui";
import { DrawerType } from "melco-shared-logic";
import { ChooseBlankList } from "./ChooseBlankList";

type ChooseBlankDrawerProps = {
  drawer: DrawerType;
};

export const ChooseBlankDrawer: React.FC<ChooseBlankDrawerProps> = ({
  drawer,
}) => {
  const { t } = useTranslation();
  const { closeDrawer, isDrawerOpen } = drawer;

  return (
    <Drawer
      title={t("products.actions.choose_blank.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={closeDrawer}
      open={isDrawerOpen}
      bodyStyle={{ padding: 0 }}
    >
      <ChooseBlankList drawer={drawer} />
    </Drawer>
  );
};
