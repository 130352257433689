import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import type { Transition } from "history";
import { useModal } from "melco-ui";
import { useFormikContext } from "formik";
import { useBlocker } from "../navigation/useBlocker";

export function useDirtyFormPrompt() {
  const { dirty } = useFormikContext();
  const { t } = useTranslation();

  const modal = useModal();

  const handleBlockedNavigation = useCallback(
    (nextLocation: Transition) => {
      modal.confirm({
        title: t("global.form.dirty_form_prompt.confirmation.title"),
        content: t("global.form.dirty_form_prompt.confirmation.message"),
        okText: t("global.form.dirty_form_prompt.confirmation.ok"),
        cancelText: t("global.form.dirty_form_prompt.confirmation.cancel"),
        onOk: nextLocation.retry,
      });
    },
    [t, modal]
  );

  useBlocker(handleBlockedNavigation, dirty);
}
