import React from "react";
import AntdModal, { ModalProps as AntdModalProps } from "antd/es/modal";
import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings } = DesignTokens;

const StyledModal = styled(AntdModal)<ModalProps>`
  .ant-modal-body {
    max-height: 70vh;
    overflow-y: auto;
    padding-top: ${spacings.small}px;
    padding-bottom: ${spacings.xsmall}px;
  }
`;

export type ModalProps = AntdModalProps;

const Modal: React.FC<ModalProps> = (props) => <StyledModal {...props} />;

export { Modal };
