import {
  BulkActionContainerCol,
  Col,
  DesignTokens,
  Table,
  TableActionBarRow,
  Text,
  ValueOrFallback,
  useMessage,
} from "melco-ui";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  BulkActionCount,
  FormattedDate,
  MelcoCoreModelsCustomProductListItem,
  Search,
  useTableSelection,
  TableCellLink,
} from "melco-shared-logic";
import { useProducts } from "../../../hooks/customization/products/useProducts";
import { PublishedState } from "../../state/PublishedState";
import { BulkActionDeleteProducts } from "./BulkActionDeleteProducts";
import { ProductsListThumbnail } from "./ProductsListThumbnail";

const { spacings } = DesignTokens;

const DETAIL_VIEW_PATH = "/customization/products";

export const ProductsList: React.FC = () => {
  const { t } = useTranslation();

  const message = useMessage();

  const {
    isLoading,
    isFetching,
    data,
    isPreviousData,
    errorComponent,
    onTableConfigChange,
    pagination,
    searchQueryChange,
    refetch,
  } = useProducts();

  const navigate = useNavigate();

  const { selectedRowKeys, setSelectedRowKeys, rowSelection, onRow } =
    useTableSelection((row: MelcoCoreModelsCustomProductListItem) => {
      navigate([DETAIL_VIEW_PATH, row.id].join("/"));
    });

  const columns = [
    {
      title: "",
      key: "thumbnail",
      dataIndex: "thumbnail",
      render: (_: string, item: MelcoCoreModelsCustomProductListItem) => (
        <ProductsListThumbnail product={item} />
      ),
      width: spacings.xlarge + 2 * spacings.middle, // 2 * spacings.middle to compensate for table padding
      height: spacings.xlarge + 2 * spacings.middle, // 2 * spacings.middle to compensate for table padding
    },
    {
      title: t("products_list.columns.name"),
      key: "name",
      dataIndex: "name",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsCustomProductListItem) => (
        <ValueOrFallback
          value={item.name}
          render={(value) => (
            <TableCellLink to={[DETAIL_VIEW_PATH, item.id].join("/")}>
              {value}
            </TableCellLink>
          )}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("products_list.columns.token"),
      key: "token",
      dataIndex: "token",
      sorter: true,
      className: "not-clickable",
      onCell: () => ({
        onClick: (e: React.MouseEvent) => {
          e.stopPropagation();
        },
      }),
      render: (_: string, item: MelcoCoreModelsCustomProductListItem) => (
        <ValueOrFallback
          value={item.token}
          render={(value) => (
            <Text
              code
              copyable={{
                onCopy: () => {
                  message.success(t("products.actions.copy.success"));
                },
                tooltips: [
                  t("products.actions.copy.tooltip_copy"),
                  t("products.actions.copy.tooltip_copied"),
                ],
              }}
            >
              {value}
            </Text>
          )}
          fallback={t("global.fallback.empty")}
        />
      ),
    },
    {
      title: t("products_list.columns.created_date"),
      key: "created_date",
      dataIndex: "created_date",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsCustomProductListItem) => (
        <FormattedDate date={item.created_date} />
      ),
    },
    {
      title: t("products_list.columns.updated_date"),
      key: "updated_date",
      dataIndex: "updated_date",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsCustomProductListItem) => (
        <FormattedDate date={item.updated_date} />
      ),
    },
    {
      title: t("products_list.columns.publish_state"),
      key: "publish_state",
      dataIndex: "publish_state",
      sorter: true,
      render: (_: string, item: MelcoCoreModelsCustomProductListItem) => {
        return <PublishedState publishedState={item.publish_state ?? 1} />;
      },
    },
  ];

  return (
    <>
      <TableActionBarRow>
        <Col>
          <Search
            onSearchInputChange={(query: string) => searchQueryChange(query)}
          />
        </Col>
        <BulkActionContainerCol>
          <BulkActionCount selectedCount={selectedRowKeys.length} />
          <BulkActionDeleteProducts
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            refetch={refetch}
          />
        </BulkActionContainerCol>
      </TableActionBarRow>
      <Table
        showSorterTooltip={false}
        sticky
        loading={isPreviousData ? isFetching : isLoading}
        dataSource={data}
        columns={columns}
        onChange={onTableConfigChange}
        pagination={pagination}
        rowKey="id"
        rowSelection={rowSelection}
        onRow={onRow}
        rowClassName={() => "clickable"}
        noDataMessage={t("products_list.empty")}
        errorComponent={errorComponent}
      />
    </>
  );
};
