import React from "react";
import styled, { css } from "styled-components";
import AntdTypography from "antd/es/typography";
import { ParagraphProps as AntdParagraphProps } from "antd/es/typography/Paragraph";
import { DesignTokens } from "../../styles/design-tokens";

const { fonts } = DesignTokens;

const StyledParagraph = styled(AntdTypography.Paragraph).withConfig({
  shouldForwardProp: (prop) => !["alignment", "size"].includes(prop),
})<ParagraphProps>`
  ${({ size, alignment }) => css`
    ${alignment && `text-align: ${alignment}`};

    ${size === "small" &&
    `&& {
      font-size: ${fonts.sizes.small}px;

      a {
        font-size: ${fonts.sizes.small}px;
      }
    }`}
  `}
`;

interface MelcoParagraphProps {
  alignment?: "left" | "center" | "right";
  size?: "normal" | "small";
}

export type ParagraphProps = MelcoParagraphProps & AntdParagraphProps;

const Paragraph: React.FC<ParagraphProps> = (props) => (
  <StyledParagraph {...props} />
);

export { Paragraph };
