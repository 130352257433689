import { Drawer } from "melco-ui";
import { useTranslation } from "react-i18next";
import { BlankReferencesList } from "./BlankReferencesList";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";

type BlankReferencesDrawerProps = {
  drawer: DrawerType;
  id: string;
};

export const BlankReferencesDrawer: React.FC<BlankReferencesDrawerProps> = ({
  drawer,
  id: blankId,
}) => {
  const { t } = useTranslation();
  const { closeDrawer, isDrawerOpen } = drawer;

  return (
    <Drawer
      title={t("blanks.actions.references.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={closeDrawer}
      open={isDrawerOpen}
      bodyStyle={{ padding: 0 }}
    >
      <BlankReferencesList blankId={blankId} />
    </Drawer>
  );
};
