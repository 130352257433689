import { createGlobalStyle } from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { colors, fonts, spacings } = DesignTokens;

const GlobalStyles = createGlobalStyle`
  body {
    color: ${colors.main.positive};
    font-size: ${fonts.sizes.base}px;
    font-family: ${fonts.sansSerif};
    background: ${colors.main.negative};
    line-height: ${fonts.lineHeight.base};
  }

  .ant-modal-confirm-content {
    padding-top: ${spacings.xsmall}px;
    padding-bottom: ${spacings.small}px;
  }
`;

export { GlobalStyles };
