import { ReactNode, useCallback } from "react";
import { TOptions } from "i18next";
import { useTranslation } from "react-i18next";
import { useModal } from "melco-ui";

export type UseConfirmOptions = {
  translationPrefix: string;
  translationOptions?: TOptions;
  content?: ReactNode;
  width?: string | number;
};

const translationKeys = (prefix: string, key: string) => {
  return [
    [prefix, "confirmation", key].join("."),
    ["global", "confirmation", key].join("."),
  ];
};

export const useConfirm = (
  onOk: () => Promise<void>,
  { translationPrefix, translationOptions, content, width }: UseConfirmOptions
) => {
  const { t } = useTranslation();

  const modal = useModal();

  return useCallback(() => {
    modal.confirm({
      title: t(
        translationKeys(translationPrefix, "title") as any,
        translationOptions ?? {}
      ),
      content: content
        ? content
        : t(
            translationKeys(translationPrefix, "message") as any,
            translationOptions ?? {}
          ),
      okText: t(
        translationKeys(translationPrefix, "ok") as any,
        translationOptions ?? {}
      ),
      cancelText: t(
        translationKeys(translationPrefix, "cancel") as any,
        translationOptions ?? {}
      ),
      onOk,
      width,
    });
  }, [t, onOk, content, translationPrefix, translationOptions, width]);
};
