import AntdTable, { TableProps as AntdTableProps } from "antd/es/table";
import ConfigProvider from "antd/es/config-provider";
import Empty from "antd/es/empty";
import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { spacings } = DesignTokens;

const StyledTable = styled(AntdTable)`
  tr.clickable {
    cursor: pointer;
  }

  td.not-clickable {
    cursor: default;
  }

  tr > td:first-child,
  tr > th:first-child {
    padding-left: ${spacings.xlarge}px;

    .ant-table-column-sorters {
      padding-left: 0;
    }
    &.ant-table-selection-column {
      padding-inline-start: ${spacings.xlarge}px;
    }
  }

  .ant-table-selection-col {
    /* as we add xlarge-padding we need to make sure the column is bigger than the usual 32px */
    width: ${spacings.xlarge + spacings.xlarge}px;
    min-width: ${spacings.xlarge + spacings.xlarge}px;
  }

  tr > td:last-child,
  tr > th:last-child {
    padding-right: ${spacings.xlarge}px;

    .ant-table-column-sorters {
      padding-right: 0;
    }
  }

  .ant-table-pagination.ant-pagination {
    margin: ${spacings.large}px ${spacings.xlarge}px ${spacings.xlarge}px
      ${spacings.large}px;
  }

  /* offset the (not displayed) image above the text */
  .ant-empty {
    margin-bottom: 100px;
  }
`;

interface MelcoTableProps {
  noDataMessage?: string;
  errorComponent?: React.ReactNode;
}

export type TableProps<T> = MelcoTableProps & AntdTableProps<T>;

const Table = <T extends object = any>({
  noDataMessage,
  errorComponent,
  ...rest
}: TableProps<T>) => {
  if (errorComponent) {
    return (
      <ConfigProvider renderEmpty={() => errorComponent}>
        {
          // FIXME
          //@ts-ignore
          <StyledTable {...rest} dataSource={[]} />
        }
      </ConfigProvider>
    );
  }

  if (noDataMessage) {
    return (
      <ConfigProvider
        renderEmpty={() => <Empty image={null} description={noDataMessage} />}
      >
        {
          // FIXME
          //@ts-ignore
          <StyledTable {...rest} />
        }
      </ConfigProvider>
    );
  }
  // FIXME
  //@ts-ignore
  return <StyledTable {...rest} />;
};

export { Table };
