import styled from "styled-components";
import { Typography } from "antd";
import { DesignTokens } from "../../../styles/design-tokens";
import { ValueOrFallback } from "../../table/ValueOrFallback";

const { Text } = Typography;
const { fonts } = DesignTokens;

const LabeledItemWrapper = styled.div``;

const Label = styled(Text)`
  display: block;
  font-family: ${fonts.sansSerifMd};
`;

export type LabeledItemProps = {
  label: string;
  content?: string | React.ReactNode;
  fallback?: string;
};

export const LabeledItem: React.FC<LabeledItemProps> = ({
  label,
  content,
  fallback,
}) => {
  return (
    <LabeledItemWrapper>
      <Label>{label}</Label>
      <Text>
        <ValueOrFallback value={content} fallback={fallback ?? ""} />
      </Text>
    </LabeledItemWrapper>
  );
};
