import { useTranslation } from "react-i18next";
import { Drawer } from "melco-ui";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";
import { AddDesignList } from "./AddDesignList";

type AddDesignsDrawerProps = {
  drawer: DrawerType;
};

export const AddDesignsDrawer: React.FC<AddDesignsDrawerProps> = ({
  drawer,
}) => {
  const { t } = useTranslation();
  const { closeDrawer, isDrawerOpen } = drawer;

  return (
    <Drawer
      title={t("libraries.design_collections.actions.add_designs.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={closeDrawer}
      open={isDrawerOpen}
      bodyStyle={{ padding: 0 }}
    >
      <AddDesignList />
    </Drawer>
  );
};
