import { Card, Statistic, Title } from "melco-ui";
import { useTranslation } from "react-i18next";
import { calculateTotalDesignsAnalytics } from "../../../helper/analyticsHelper";
import { useDesignsAnalytics } from "../../../hooks/analytics/useDesignsAnalytics";

type ProductionFilesCardProps = {
  startTimestamp?: Date;
  endTimestamp?: Date;
};

export const ProductionFilesCard: React.FC<ProductionFilesCardProps> = ({
  startTimestamp,
  endTimestamp,
}) => {
  const { t } = useTranslation();

  const { dailySummary, isLoading } = useDesignsAnalytics(
    startTimestamp,
    endTimestamp
  );
  const { totalFiles } = calculateTotalDesignsAnalytics(dailySummary);

  return (
    <Card
      title={t("dashboard.statistics.production_files.title")}
      data-cy-id="dashboard-statistics-production-files"
    >
      <Statistic
        title={t("dashboard.statistics.production_files.downloaded.title")}
        value={totalFiles}
        loading={isLoading}
      />
    </Card>
  );
};
