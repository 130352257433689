import { Drawer } from "melco-ui";
import { useTranslation } from "react-i18next";
import { BlankPictureReferencesList } from "./BlankPictureReferencesList";
import { Drawer as DrawerType } from "../../../../hooks/drawer/useGenericDrawer";

type BlankPictureReferencesDrawerProps = {
  drawer: DrawerType;
  id: string;
};

export const BlankPictureReferencesDrawer: React.FC<
  BlankPictureReferencesDrawerProps
> = ({ drawer, id: blankPictureId }) => {
  const { t } = useTranslation();
  const { closeDrawer, isDrawerOpen } = drawer;

  return (
    <Drawer
      title={t("blank_pictures.actions.references.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={closeDrawer}
      open={isDrawerOpen}
      bodyStyle={{ padding: 0 }}
    >
      <BlankPictureReferencesList blankPictureId={blankPictureId} />
    </Drawer>
  );
};
