import styled from "styled-components";
import { Switch as AntdSwitch, SwitchProps as AntdSwitchProps } from "antd";

import { DesignTokens } from "../../../styles/design-tokens";

const { colors } = DesignTokens;

export type { AntdSwitchProps as SwitchProps };

export const Switch = styled(AntdSwitch)`
  &.ant-switch-checked,
  &.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: ${colors.feedback.success};
  }
`;
