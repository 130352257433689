import { useCallback } from "react";
import { FormikHelpers } from "formik";
import forEach from "lodash-es/forEach";
import { useTranslation } from "react-i18next";
import { useMessage } from "melco-ui";
import {
  APIError,
  useErrorMessageMapping,
} from "../error/useErrorMessageMapping";
import { useSubmitResultHandler } from "./useSubmitResultHandler";

export type UseFormSubmitOptions<T> = {
  translationPrefix: string;
  onSuccess?: (values: T, formikHelpers: FormikHelpers<T>) => void;
  onError?: (values: T, formikHelpers: FormikHelpers<T>) => void;
  onSuccessOrError?: () => void;
};

export type OnSubmit<T> = (
  values: T,
  formikHelpers: FormikHelpers<T>
) => OnSubmitReturn | void | Promise<OnSubmitReturn | void>;

export type OnSubmitReturn = {
  successMessages: TranslatableMessageWithOptionalTitle[];
};

export type TranslatableMessageWithOptionalTitle = {
  title?: string | string[];
  message: string | string[];
};

export const useFormSubmit = <T>(
  onSubmit: OnSubmit<T>,
  options: UseFormSubmitOptions<T> & {
    isFlowForm?: boolean;
  }
) => {
  const message = useMessage();

  const {
    translationPrefix,
    isFlowForm,
    onSuccess,
    onError,
    onSuccessOrError,
  } = options;

  const mapErrors = useErrorMessageMapping();

  const handleSubmitResult = useSubmitResultHandler(
    translationPrefix,
    isFlowForm
  );

  const { t } = useTranslation();

  return useCallback(
    async (
      values: T,
      formikHelpers: FormikHelpers<T>,
      options?: {
        isManuallyResettingForm?: boolean;
      }
    ) => {
      try {
        formikHelpers.setStatus({});
        const submitResult = await onSubmit(values, formikHelpers);
        handleSubmitResult(submitResult);

        // reset form so dirty tracking works properly
        if (!options?.isManuallyResettingForm) {
          formikHelpers.resetForm({ values });
        }

        if (onSuccess) {
          onSuccess(values, formikHelpers);
        }
      } catch (error) {
        const { fieldErrorMessages, globalErrorMessages, errorMessages } =
          await mapErrors(error as APIError[], values);

        // field specific error messages
        forEach(fieldErrorMessages, (errorMessages, fieldName) => {
          formikHelpers.setFieldError(fieldName, errorMessages.join(", "));
        });

        // update error messages (stored in formik status)
        formikHelpers.setStatus({
          errorMessages,
          globalErrorMessages,
          fieldErrorMessages,
        });

        // feedback message
        if (!isFlowForm) {
          message.error(t("global.error.form.title"));
        }

        if (onError) {
          onError(values, formikHelpers);
        }
      }
      if (onSuccessOrError) {
        onSuccessOrError();
      }
    },
    [
      onSubmit,
      mapErrors,
      handleSubmitResult,
      isFlowForm,
      onSuccess,
      onError,
      onSuccessOrError,
      t,
    ]
  );
};
