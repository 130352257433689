import { Locale } from "antd/es/locale";

import en from "antd/es/locale/en_US";
import de from "antd/es/locale/de_DE";
import it from "antd/es/locale/it_IT";
import fr from "antd/es/locale/fr_FR";
import sv from "antd/es/locale/sv_SE";
import nl from "antd/es/locale/nl_NL";
import nb from "antd/es/locale/nb_NO"; //Antd has no nn_no, we can use nb_no instead
import pt from "antd/es/locale/pt_PT";
import es from "antd/es/locale/es_ES";

import { useApplicationLanguage } from "./useApplicationLanguage";
import { useSetMomentLocale } from "./useSetMomentLocale";
import { useSetDayjsLocale } from "./useSetDayjsLocale";

//Add for every supported language a matching import from antd
const languageToLocaleMapping: { [key: string]: Locale | undefined } = {
  en,
  de,
  it,
  fr,
  sv,
  nl,
  nn: nb,
  pt,
  es,
};

export const useAntdLocale = () => {
  //Moment will be removed with Antd 5 and replaced with dayjs
  useSetMomentLocale();

  useSetDayjsLocale();

  const language = useApplicationLanguage();
  return languageToLocaleMapping[language] ?? en;
};
