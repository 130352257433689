import { Drawer } from "melco-ui";
import { useTranslation } from "react-i18next";
import { AddBlankPicturesList } from "./AddBlankPicturesList";

type AddBlankPicturesDrawerProps = {
  isDrawerOpen: boolean;
  setDrawerOpen: (isDrawerOpen: boolean) => void;
};

export const AddBlankPicturesDrawer: React.FC<AddBlankPicturesDrawerProps> = ({
  isDrawerOpen,
  setDrawerOpen,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      title={t("blanks.actions.add_blank_pictures.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={() => setDrawerOpen(false)}
      open={isDrawerOpen}
      bodyStyle={{ padding: 0 }}
    >
      <AddBlankPicturesList />
    </Drawer>
  );
};
