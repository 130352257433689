import { Drawer } from "melco-ui";
import { useTranslation } from "react-i18next";
import { ColorCollectionReferencesList } from "./ColorCollectionReferencesList";
import { Drawer as DrawerType } from "../../../hooks/drawer/useGenericDrawer";

type ColorCollectionReferencesDrawerProps = {
  drawer: DrawerType;
  id: string;
};

export const ColorCollectionReferencesDrawer: React.FC<
  ColorCollectionReferencesDrawerProps
> = ({ drawer, id: colorCollectionId }) => {
  const { t } = useTranslation();
  const { closeDrawer, isDrawerOpen } = drawer;

  return (
    <Drawer
      title={t("color_collections.actions.references.title")}
      placement="right"
      width={1000}
      closable={true}
      onClose={closeDrawer}
      open={isDrawerOpen}
      bodyStyle={{ padding: 0 }}
    >
      <ColorCollectionReferencesList colorCollectionId={colorCollectionId} />
    </Drawer>
  );
};
