import styled from "styled-components";
import AntdBadge, { BadgeProps as AntdBadgeProps } from "antd/es/badge";
import { DesignTokens } from "../../styles/design-tokens";

const { colors } = DesignTokens;

export const StyledBadge = styled(AntdBadge)`
  && .ant-badge-status-processing {
    color: ${colors.main.primary};
    background-color: ${colors.main.primary};
  }
`;

export type BadgeProps = AntdBadgeProps;

const Badge: React.FC<BadgeProps> = (props) => <StyledBadge {...props} />;

export { Badge };
