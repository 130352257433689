import React from "react";
import styled from "styled-components";
import { ColorHelper } from "../../../helper/ColorHelper";
import { DesignTokens } from "../../../styles/design-tokens";

const { argbToRGB, rgbToARGB } = ColorHelper;
const { spacings } = DesignTokens;

const StyledColorInput = styled.input`
  padding: 0;
  width: ${spacings.middle}px;
  cursor: pointer;
  height: ${spacings.middle}px;
  ::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  ::-webkit-color-swatch {
    border: none;
  }
  border: 1px solid #888888;
  border-radius: 2px;
  ::-moz-color-swatch {
    border: none;
    height: ${spacings.middle - 2}px;
  }
`;

export type SwatchColorPickerProps = {
  argb?: string;
  onChange: (argb?: string) => void;
};

const SwatchColorPicker: React.FC<SwatchColorPickerProps> = ({
  argb,
  onChange,
}) => (
  <StyledColorInput
    type="color"
    value={argb && argbToRGB(argb)}
    onChange={(event) => {
      onChange && onChange(rgbToARGB(event.target.value));
    }}
  />
);

export { SwatchColorPicker };
