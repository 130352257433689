import AntdCard from "antd/es/card";
import styled from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";
export type { CardProps } from "antd/es/card";

const { fonts } = DesignTokens;

const Card = styled(AntdCard)`
  && {
    .ant-card-head-title {
      font-family: ${fonts.sansSerifMd};
    }
  }
`;

export { Card };
