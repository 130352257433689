import { ThemeConfig } from "antd";
import { DesignTokens } from "../styles/design-tokens";
const { colors, borders, fonts, spacings, sizes, animations } = DesignTokens;

export const useMelcoUITheme = (): ThemeConfig => {
  return {
    components: {
      Button: {
        defaultBg: "transparent",
        defaultHoverBg: "transparent",
        defaultActiveBg: "transparent",
      },
      Badge: {
        statusSize: 8, //New
      },
      Input: {
        hoverBorderColor: colors.border.dark,
      },
      Form: {
        paddingXS: spacings.xsmall, //Ensure smaller spacing between label and input
      },
      Layout: {
        siderBg: colors.main.negative, //New
        triggerBg: colors.main.negative, //New
        triggerColor: colors.main.positive, //New
      },
      Menu: {
        activeBarBorderWidth: 0, //New
        activeBarWidth: 2, //New
        itemMarginInline: 0, //New
      },
      DatePicker: {
        padding: 12, //Used to calculate padding around calendar
        paddingXXS: 2, //Used for padding between cells
      },
      Table: {
        cellPaddingBlock: spacings.middle,
        cellPaddingInline: spacings.middle,
        headerBg: colors.main.negative, //New
        rowHoverBg: colors.gray.superLight,
        rowSelectedBg: colors.table.selectedBg,
        rowSelectedHoverBg: colors.table.selectedHoverBg,
      },
    },
    token: {
      colorBgContainerDisabled: colors.gray.light,
      colorBgLayout: colors.main.negative,
      colorText: colors.main.positive,
      colorPrimary: colors.main.primary,
      colorLink: colors.link.default,
      colorLinkHover: colors.link.hover,
      colorSuccess: colors.feedback.success,
      colorError: colors.feedback.error,
      colorInfo: colors.feedback.info,
      colorWarning: colors.feedback.warning,
      borderRadius: borders.radius.normal,
      fontFamily: fonts.sansSerif,
      fontWeightStrong: 500,
      fontSize: fonts.sizes.base,
      fontSizeLG: fonts.sizes.large,
      fontSizeSM: fonts.sizes.small,
      fontSizeHeading1: fonts.sizes.headings.xxxlarge,
      fontSizeHeading2: fonts.sizes.headings.xxlarge,
      fontSizeHeading3: fonts.sizes.headings.xlarge,
      fontSizeHeading4: fonts.sizes.headings.large,
      fontSizeHeading5: fonts.sizes.headings.base,
      lineHeight: fonts.lineHeight.base,
      lineHeightLG: fonts.lineHeight.base,
      screenXS: sizes.xs,
      screenSM: sizes.sm,
      screenMD: sizes.md,
      screenLG: sizes.lg,
      screenXL: sizes.xl,
      screenXXL: sizes.xxl,
      motionEaseOut: animations.easing.out,
      motionEaseInOut: animations.easing.inOut,
      motionDurationSlow: `${animations.durations.slow}s`,
      motionDurationMid: `${animations.durations.base}s`,
      motionDurationFast: `${animations.durations.fast}s`,
    },
  };
};

//  Unmigrated less variables
// "@normal-color": colors.main.primary,
// "@background-color-light": colors.gray.superLight,
// "@background-color-base": colors.gray.light,
// "@outline-color": colors.border.dark,
// "@page-header-padding": 0,
// "@page-header-padding-vertical": 0,
// "@page-header-heading-title": "@heading-3-size",
// "@drawer-header-padding": `${spacings.middle}px ${spacings.large}px`,
// "@tag-line-height": "22px",
// "@collapse-header-bg": colors.gray.superLight,
// "@ease-in": animations.easing.in,
