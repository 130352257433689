import React from "react";
import AntdLayout, { Content as AntdContent } from "antd/es/layout/layout";
import styled from "styled-components";
import { Footer } from "./Footer";
import { GlobalStyles } from "../components/appearance/GlobalStyles";
import { MelcoUIApp } from "../components/other/MelcoUIApp";

const MainLayout = styled(AntdLayout)`
  min-height: 100vh;
`;

const MainContentAreaWrapper = styled(AntdContent)`
  width: 100%;
  position: relative;
`;

const MainContentArea = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export type ApplicationLayoutProps = {
  children: React.ReactNode;
  mainNavigation?: React.ReactNode;
  footer?: React.ReactNode;
  contentPadding?: boolean;
  contentAreaRef?: React.RefObject<HTMLDivElement>;
  alert?: React.ReactNode;
};

export const ApplicationLayout: React.FC<ApplicationLayoutProps> = ({
  children,
  mainNavigation,
  footer,
  contentAreaRef,
  alert,
}) => (
  <MelcoUIApp>
    <MainLayout hasSider>
      <GlobalStyles />

      {mainNavigation}

      <AntdLayout>
        {alert}

        <MainContentAreaWrapper>
          <MainContentArea ref={contentAreaRef}>
            {children}
            {footer && <Footer>{footer}</Footer>}
          </MainContentArea>
        </MainContentAreaWrapper>
      </AntdLayout>
    </MainLayout>
  </MelcoUIApp>
);
