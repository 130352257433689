import { useCallback } from "react";
import { useMessage } from "melco-ui";
import castArray from "lodash-es/castArray";
import isEmpty from "lodash-es/isEmpty";
import { useTranslationWithFallback } from "../translation/useTranslationWithFallback";
import { OnSubmitReturn } from "./useFormSubmit";

export const useSubmitResultHandler = (
  translationPrefix: string,
  isFlowForm?: boolean
) => {
  const message = useMessage();

  const translateWithFallback = useTranslationWithFallback(
    [translationPrefix, "success"].join(".")
  );

  return useCallback(
    (submitResult: void | OnSubmitReturn) => {
      if (submitResult) {
        if (isFlowForm) {
          // Flow form success messages are usually displayed on other pages and therefore passed via URL params
        } else {
          const successMessages = submitResult.successMessages.map(
            (successMessage) => {
              return translateWithFallback(
                castArray(successMessage.message),
                castArray(successMessage.message).join("")
              );
            }
          );

          if (!isEmpty(successMessages)) {
            message.success(successMessages.join(", "));
          }
        }
      }
    },
    [isFlowForm, translateWithFallback]
  );
};
