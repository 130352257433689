import React from "react";
import AntdAlert, { AlertProps as AntdAlertProps } from "antd/es/alert";
import styled, { css } from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";

const { colors, fonts, spacings } = DesignTokens;

const AlertWrapper = styled.div`
  margin: -${spacings.middle}px 0 ${spacings.xlarge}px 0;
`;

const AlertList = styled.ul`
  padding: 0 0 0 ${spacings.middle}px;
  margin: 0;
`;

const StyledAlert = styled(AntdAlert).withConfig({
  shouldForwardProp: (prop) => !["withBackground"].includes(prop),
})<AlertProps>`
  && {
    ${({ withBackground }) =>
      withBackground
        ? css`
            padding: ${spacings.middle}px;
          `
        : css`
            border: 0;
            background-color: transparent;
            padding: 0 0 ${spacings.middle}px 0;
          `}

    .ant-alert-icon {
      font-size: 20px;
      align-self: flex-start;
      padding: 2px 2px 0 4px;
    }

    .ant-alert-message {
      font-size: ${fonts.sizes.large}px;

      ${(props) =>
        props.type &&
        css`
          color: ${colors.feedback[props.type]};
        `}

      .ant-typography {
        font-size: ${fonts.sizes.large}px;
      }
    }
  }
`;

type MelcoProps = {
  withBackground?: boolean;
};

export type AlertProps = MelcoProps & AntdAlertProps;

const Alert: React.FC<AlertProps> = (props) => (
  <StyledAlert showIcon {...props} />
);

export { Alert, AlertWrapper, AlertList };
